import request from '@/utils/request'

// 购物车提交
export function orderSubmit(data) {
    return request({
        url: '/order/submit',
        method: 'post',
        data
    })
}

/**
 * lst
 * @param params
 * @returns {AxiosPromise}
 */
export function orderLst(params) {
    return request({
        url: '/order/lst',
        method: 'get',
        params
    })
}

// 订单验证
export function confirm(data) {
    return request({
        url: '/order/confirm',
        method: 'post',
        data
    })
}

// 订单信息
export function orderInfo(params)
{
    return request({
        url: '/order/detail',
        method: 'get',
        params
    })
}

// 订单发货
export function orderDeliver(params)
{
    return request({
        url: '/order/deliver',
        method: 'POST',
        params
    })
}

// 订单待审核
export function orderVerification(params)
{
    return request({
        url: '/order/verification',
        method: 'POST',
        params
    })
}