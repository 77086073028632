<template>
	<div class="confirm">
		<div class="confirm-address">
			<van-cell icon="contact" is-link style="padding: 15px 16px;">
				<template #title thumb="https://img.yzcdn.cn/vant/ipad.jpeg">
					<div><span class="contact-name">{{ address.name }}</span><span
							class="contact-tel">{{ address.tel }}</span></div>
				</template>
				<template #label>
					<div>{{ address.detail }}</div>
				</template>
			</van-cell>
			<div class="confirm-address-divide"></div>
		</div>
		<div class="confirm-product">
			<van-list class="confirm-list" v-model:loading="state.loading" :finished="state.finished" @load="load">
				<div v-for="(item, index) in order_info.products" :key="index">
					<van-card :num="item.count" :price="item.price" :desc="item.unit" :title="item.name"
						:thumb="imageUrl+item.image" />
					<van-divider v-show="index + 1 < order_info.products.length" />
				</div>
			</van-list>
		</div>
		<div class="confirm-total">
			<van-cell title="金额" :value="order_info.money" />
			<van-cell value-class="confirm-price" title="实际支付" :value="order_info.money" />
			<van-cell title="订单状态" :value="pay_status"></van-cell>
			<van-cell title="发货状态" :value="take_status"></van-cell>
			<van-cell title="订单单号" :value="order_info.order_num" />
			<van-cell title="订单创建时间" :value="order_info.create_time"></van-cell>
			<van-cell title="订单支付时间" :value="order_info.pay_time" />
			<van-cell title="取货码" :value="order_info.take_num" />
			<van-cell title="收货方式" :value="take_type" />
			<van-cell value-class="confirm-price" title="订单类型" :value="type" />
			<!-- <van-field v-model="order_info.remark" disabled label="备注" placeholder="备注为空！" /> -->
		</div>
	</div>
</template>

<script>
	import {
		orderInfo
	} from '@/api/order'
	import {
		Toast
	} from "vant";
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				order_id: '',
				order_info: '',
				address:'',
				imageUrl: http.imageUrl,
				pay_status: '',
				take_status: '',
				state: {
					loading: false,
					finished: true,
				},
				type: '',
				take_type: ''
			}
		},
		mounted() {
			this.order_id = this.$route.query.order_id
			// console.log(this.order_id) 
			this.orderInfo()
		},
		methods: {
			orderInfo() {
				if (!this.order_id) {
					Toast.fail('订单信息有误')
					return false;
				}
				orderInfo({
					order_id: this.order_id
				}).then((res) => {
					// console.log(res)
					this.order_info = res.data
					this.address = res.data.address
					// console.log(this.address)
					// console.log(this.order_info)
					this.pay_status = this.getOrderStatus()
					this.take_status = this.getOrderStatuss()
					this.type = this.getOrderType()
					this.take_type = this.takeType()
				})
			},
			getOrderStatus() {
				if (this.order_info.pay_status === 1) return '支付成功'
				else if (this.order_info.pay_status === 2) return '订单已取消'
				else if (this.order_info.pay_status === -1) return '已退款'
				else if (this.order_info.pay_status === 0) return '待支付'
			},
			getOrderStatuss() {
				if (this.order_info.take_status === 1) return '已发货'
				else if (this.order_info.take_status === 2) return '已签收'
				else if (this.order_info.take_status === 3) return '退货中'
				else if (this.order_info.take_status === 4) return '已退货'
				else if (this.order_info.take_status === 5) return '已核销已完成'
				else if (this.order_info.take_status === 0) return '未发货'
			},
			getOrderType() {
				if (this.order_info.type === 0) return '普通 '
				else return '预约'
			},
			takeType() {
				if (this.order_info.take_type === 0) return '外送 '
				else return '到店'
			}
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.confirm {
		min-height: calc(100vh);

		.confirm-address-divide {
			width: 100%;
			height: 2px;
			background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
			background-size: 80px;
		}

		.contact-name {
			font-size: 15px;
			font-weight: 600;
		}

		.contact-tel {
			font-size: 15px;
			padding-left: 10px;
			font-weight: 600;
		}

		.confirm-product,
		.confirm-total {
			margin: 20px 10px 0 20px;
			border-radius: 10px;
			overflow: hidden;
		}

		.confirm-total {
			padding-bottom: 20px;
		}

		::v-deep(.van-card) {
			padding: 20px;
			background: white;
		}

		::v-deep(.van-cell__left-icon) {
			margin-right: 15px;
			margin-top: 10px;
		}

		::v-deep(.van-cell__right-icon) {
			margin-left: 5px;
			color: #969799;
			margin-top: 10px;
		}

		::v-deep(.van-card__thumb) {
			width: 72px;
			height: 72px;
			margin-right: 15px;
		}

		::v-deep(.van-card__price),
		.bar-price {
			color: @price-color;
		}

		::v-deep(.confirm-price) {
			font-size: 16px;
			color: @price-color;
		}

		.confirm-bar {
			bottom: 0;
			position: fixed;
			background: white;
			width: 100%;
			padding: 15px;
			z-index: 1;
		}

		.bar-btn {
			border-radius: 10px;
			padding: 0 16px;
		}

		.bar-title {
			font-size: 15px;
			font-weight: bold;
			line-height: 35px;
		}

		.bar-price {
			line-height: 32px;
			font-size: 20px;
		}

		.bar-btn-group {
			line-height: 24px;
		}

		.confirm-address,
		.confirm-product {
			background: white;
		}

		.van-divider {
			margin: 2px 0;
			padding: 0 10px;
			border-color: #c7c7c7;
		}
	}
</style>
